@import (reference) '~@riseart/antd-provider/dist/website/variables.less';
@import (reference) '~@riseart/antd-provider/dist/website/mixins.less';

@icon-banner-cls: ~'@{ra-prefix}-icon-banner';
@icon-banner-icon-cls: ~'@{icon-banner-cls}-icon';
@icon-banner-content-cls: ~'@{icon-banner-cls}-content';
@icon-banner-content-main-cls: ~'@{icon-banner-content-cls}-main';

:export {
  iconBannerCls: @icon-banner-cls;
  iconBannerIconCls: @icon-banner-icon-cls;
  iconBannerContentCls: @icon-banner-content-cls;
  iconBannerContentMainCls: @icon-banner-content-main-cls;
}

.@{icon-banner-cls} {
  display: flex;
  justify-content: center;
  align-items: center;

  .@{icon-banner-icon-cls} {
    margin-right: 15px;
    font-size: 36px;
    line-height: 1;

    .anticon {
      vertical-align: middle;
    }
  }

  .@{icon-banner-content-cls} {
    text-align: left;

    .@{icon-banner-content-main-cls} {
      margin-right: 5px;
    }

    strong,
    .ra-link {
      font-weight: @ra-font-weight-normal;
    }
  }
}
