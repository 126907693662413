@import (reference) '~@riseart/antd-provider/dist/website/variables.less';
@import (reference) '~@riseart/antd-provider/dist/website/mixins.less';

@subscribe-form-cls: ~'@{ra-prefix}-subscribe-form';
@subscribe-form-theme-basic-cls: ~'@{subscribe-form-cls}-theme-basic';
@subscribe-form-theme-narrow-cls: ~'@{subscribe-form-cls}-theme-narrow';
@subscribe-form-wrapper-cls: ~'@{subscribe-form-cls}-wrapper';
@subscribe-form-bottom-cls: ~'@{subscribe-form-cls}-bottom';

:export {
  subscribeFormCls: @subscribe-form-cls;
  subscribeFormThemeBasicCls: @subscribe-form-theme-basic-cls;
  subscribeFormThemeNarrowCls: @subscribe-form-theme-narrow-cls;
  subscribeFormWrapperCls: @subscribe-form-wrapper-cls;
  subscribeFormBottomCls: @subscribe-form-bottom-cls;
}

.@{subscribe-form-wrapper-cls} {
  .@{subscribe-form-cls}.ra-form {
    @media only screen and (min-width: @ra-screen-sm) {
      display: flex;
      justify-content: space-between;
    }

    @media only screen and (min-width: @ra-screen-sm) {
      > * {
        flex-grow: 1;
        margin-right: 100% / 24;
      }

      > *:last-child {
        flex-grow: unset;
        width: 100% / (24/5);
        margin-right: 0;
      }
    }

    &.ra-form-horizontal {
      .ra-form-item {
        min-height: auto;

        @media only screen and (min-width: @ra-screen-sm) {
          margin-bottom: 5px;

          &:last-of-type {
            margin-left: 15px;
          }
        }

        .ra-form-input.ant-input {
          height: 46px;
          line-height: 46px;
        }

        .ra-btn.ra-btn-width-container {
          padding-left: 5px;
          padding-right: 5px;
        }
      }
    }
  }

  .@{subscribe-form-bottom-cls} {
    .mixinTextLevel6Style();

    @media only screen and (min-width: @ra-screen-md) {
      width: 100% / (24/18);
    }
  }

  &.@{subscribe-form-theme-narrow-cls} {
    .@{subscribe-form-bottom-cls} {
      width: 100%;
    }

    .@{subscribe-form-cls}.ra-form.ra-form-horizontal {
      .ra-form-item {
        @media only screen and (min-width: @ra-screen-sm) {
          &:last-of-type {
            width: 45%;
          }
        }
      }
    }
  }
}
