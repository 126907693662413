@import (reference) '~@riseart/antd-provider/dist/website/variables.less';
@import (reference) '~@riseart/antd-provider/dist/website/mixins.less';

@search-visual-hint-cls: ~'@{ra-prefix}-search-visual-hint';
@search-visual-hint-suggestions-cls: ~'@{search-visual-hint-cls}-suggestions';

:export {
  searchVisualHintCls: @search-visual-hint-cls;
  searchVisualHintSuggestionsCls: @search-visual-hint-suggestions-cls;
}

.@{search-visual-hint-cls} {
  margin-top: 50px;
  margin-bottom: 0;
  padding: 0 11px;
  text-align: center;

  @media only screen and (min-width: @ra-screen-md) {
    margin-top: 5px;
    text-align: left;
  }

  &.@{search-visual-hint-suggestions-cls} {
    margin-top: 5px;
    padding-left: 0;
    text-align: left;
  }

  .anticon {
    font-size: 22px;
    margin-right: 5px;
  }
}
