@import (reference) '~@riseart/antd-provider/dist/website/variables.less';
@import (reference) '~@riseart/antd-provider/dist/website/mixins.less';

@regional-overlay-cls: ~'@{ra-prefix}-regional-overlay';

:export {
  regionalOverlayCls: @regional-overlay-cls;
}

.@{regional-overlay-cls} {
  .ra-overlay-header {
    background: @ra-color-background;
  }
}
