@import (reference) '~@riseart/antd-provider/dist/website/variables.less';
@import (reference) '~@riseart/antd-provider/dist/website/mixins.less';

@kazoart-banner-cls: ~'@{ra-prefix}-kazoart-app-banner';
@kazoart-banner-img-cls: ~'@{kazoart-banner-cls}-img';
@kazoart-banner-content-cls: ~'@{kazoart-banner-cls}-content';

:export {
  kazoartBannerCls: @kazoart-banner-cls;
  kazoartBannerImageCls: @kazoart-banner-img-cls;
  kazoartBannerContentCls: @kazoart-banner-content-cls;
}

.@{kazoart-banner-cls} {
  @media only screen and (min-width: @ra-screen-md) {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .@{kazoart-banner-img-cls} {
    @media only screen and (min-width: @ra-screen-md) {
      margin-right: 15px;
    }
  }

  .@{kazoart-banner-content-cls} {
    margin-top: 3px;

    @media only screen and (min-width: @ra-screen-md) {
      text-align: left;
    }

    strong,
    .ra-link {
      font-weight: @ra-font-weight-normal;
    }
  }
}
