@import (reference) '~@riseart/antd-provider/dist/website/variables.less';

@cart-actions-overlay-cls: ~'@{ra-prefix}-cart-actions-overlay';

:export {
  cartActionsOverlayCls: @cart-actions-overlay-cls;
}

.@{cart-actions-overlay-cls} {
  .ra-overlay-container {
    max-width: 435px;

    .ra-overlay-header {
      padding-bottom: 0;
    }
  }
}
