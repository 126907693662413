@import (reference) '~@riseart/antd-provider/dist/website/variables.less';
@import (reference) '~@riseart/common/es/button/Button.less';
@import (reference) '../../../common/file/UploadInfo.less';

@lens-overlay-cls: ~'@{ra-prefix}-lens-overlay';
@lens-overlay-video-cls: ~'@{lens-overlay-cls}-video';
@lens-overlay-picture-cls: ~'@{lens-overlay-cls}-pic';
@lens-overlay-picture-show-cls: ~'@{lens-overlay-picture-cls}-show';
@lens-overlay-picture-btn-cls: ~'@{lens-overlay-picture-cls}-btn';
@lens-overlay-screen-options-cls: ~'@{lens-overlay-cls}-screen-options';
@lens-overlay-screen-options-content-cls: ~'@{lens-overlay-screen-options-cls}-content';
@lens-overlay-content-description-cls: ~'@{lens-overlay-screen-options-content-cls}-description';
@lens-overlay-content-hint-cls: ~'@{lens-overlay-screen-options-content-cls}-hint';
@lens-overlay-main-btn-cls: ~'@{lens-overlay-cls}-main-btn';
@lens-overlay-toolbar-cls: ~'@{lens-overlay-cls}-toolbar';
@lens-overlay-header-cls: ~'@{lens-overlay-cls}-header';
@lens-overlay-camera-cls: ~'@{lens-overlay-cls}-camera';
@lens-overlay-gallery-cls: ~'@{lens-overlay-cls}-gallery';

:export {
  lensOverlayCls: @lens-overlay-cls;
  lensOverlayHeaderCls: @lens-overlay-header-cls;
  lensOverlayVideoCls: @lens-overlay-video-cls;
  lensOverlayPictureCls: @lens-overlay-picture-cls;
  lensOverlayPictureShowCls: @lens-overlay-picture-show-cls;
  lensOverlayPictureBtnCls: @lens-overlay-picture-btn-cls;
  lensOverlayCameraCls: @lens-overlay-camera-cls;
  lensOverlayGalleryCls: @lens-overlay-gallery-cls;
  lensOverlayScreenOptionsCls: @lens-overlay-screen-options-cls;
  lensOverlayScreenOptionsContentCls: @lens-overlay-screen-options-content-cls;
  lensOverlayContentDescriptionCls: @lens-overlay-content-description-cls;
  lensOverlayContentHintCls: @lens-overlay-content-hint-cls;
  lensOverlayMainBtnCls: @lens-overlay-main-btn-cls;
  lensOverlayToolbarCls: @lens-overlay-toolbar-cls;
}

.@{lens-overlay-cls}.ra-overlay {
  background-color: @ra-color-black;

  .ra-icon-close {
    position: absolute;
    top: 10px;
    right: 15px;
    z-index: @ra-zindex-2;
    background: @ra-color-white;
    color: @ra-color-text;
    width: 34px;
    height: 34px;
    border-radius: 50%;
  }

  .ant-col {
    img {
      width: 100%;
    }
  }

  .@{lens-overlay-header-cls} {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 20px;
    color: @ra-color-white;
    z-index: @ra-zindex-2;

    .ra-btn {
      position: absolute;
      top: 15px;
      right: 15px;
      z-index: @ra-zindex-1;
      background: @ra-color-white;
      border-color: @ra-color-white;
      color: @ra-color-text;
    }
  }

  .@{lens-overlay-toolbar-cls} {
    position: absolute;
    bottom: 40px;
    width: 100%;
    z-index: @ra-zindex-2;

    .ant-row {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }

    .ra-btn,
    .ra-btn:hover {
      background: @ra-color-white;
      border-color: @ra-color-white;
      color: @ra-color-text;
      font-size: 22px;

      &.ra-btn-size-large.ra-btn-circle {
        width: @ra-btn-size-xlarge-height;
        height: @ra-btn-size-xlarge-height;
      }

      &.ra-btn-size-xlarge {
        width: 80px;
        height: 80px;
        font-size: 32px;
      }
    }
  }

  .@{lens-overlay-main-btn-cls},
  .@{lens-overlay-main-btn-cls}:hover {
    background: @ra-color-white;
    border-color: @ra-color-white;
    color: @ra-color-text;
  }

  .@{lens-overlay-screen-options-cls} {
    position: relative;
    padding: 20px;
    height: 100%;
    color: @ra-color-white;

    .ra-heading {
      color: @ra-color-white;
    }

    .@{lens-overlay-screen-options-content-cls} {
      margin-top: 150px;
      text-align: center;

      .@{lens-overlay-content-description-cls} {
        margin-bottom: 75px;
      }

      .@{lens-overlay-content-hint-cls} {
        margin-top: 15px;
        font-size: 12px;
      }
    }
  }

  .@{lens-overlay-video-cls},
  .@{lens-overlay-picture-cls} {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100vw;
    height: 100vh;
    object-fit: cover;
  }

  .@{lens-overlay-video-cls} {
    z-index: @ra-zindex-1;
  }

  .@{lens-overlay-picture-cls} {
    z-index: @ra-zindex-1;
    display: none;

    &.@{lens-overlay-picture-show-cls} {
      display: block;
    }
  }

  .@{lens-overlay-picture-btn-cls} {
    background: rgba(@ra-color-white, 0.55);
    color: @ra-color-text;
    font-size: 22px;
  }

  .@{file-upload-info-cls} {
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    padding: 10px;
    z-index: @ra-zindex-2;
    color: @ra-color-white;
    background-color: transparent;
    text-align: left;

    .@{file-upload-info-progress-cls} {
      background-color: @ra-color-white;
    }
  }

  .@{lens-overlay-camera-cls} {
    .@{file-upload-info-cls} {
      box-shadow: 0 0 12px 8px rgba(@ra-color-black, 0.5);
      background-color: rgba(@ra-color-black, 0.5);
      border-radius: 5px;
      color: @ra-color-white;
    }
  }

  .@{lens-overlay-gallery-cls} {
    .ra-ripple-wrapper .ra-ripple-container > .ra-ripple-image {
      background-color: rgba(@ra-color-black, 0.5);
    }

    .@{lens-overlay-screen-options-content-cls} {
      margin-top: 80px;

      .ant-row {
        padding-bottom: 15px;
      }
    }
  }
}
