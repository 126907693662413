@import (reference) '~@riseart/antd-provider/dist/website/variables.less';
@import (reference) '~@riseart/form/lib/form/Form.less';

@payment-step-cls: ~'@{ra-prefix}-payment-step';
@payment-step-card-row-cls: ~'@{payment-step-cls}-card-row';
@payment-step-card-row-item-cls: ~'@{payment-step-card-row-cls}-item';

@payment-card-form-cls: ~'@{ra-prefix}-payment-card-form';
@payment-card-input-cls: ~'@{payment-card-form-cls}-input';
@payment-card-input-number-cls: ~'@{payment-card-input-cls}-number';
@payment-card-input-brand-cls: ~'@{payment-card-input-cls}-brand';
@payment-card-input-validthru-cls: ~'@{payment-card-input-cls}-validthru';
@payment-card-input-cvc-cls: ~'@{payment-card-input-cls}-cvc';
@payment-card-input-cvc-container-cls: ~'@{payment-card-input-cvc-cls}-container';
@payment-card-form-row-cls: ~'@{payment-card-form-cls}-row';
@payment-card-item-stripe-cls: ~'@{payment-card-input-cls}-item-stripe';
@payment-card-input-stripe-cls: ~'@{payment-card-input-cls}-stripe';

:export {
  paymentStepCls: @payment-step-cls;
  paymentStepCardRowCls: @payment-step-card-row-cls;
  paymentStepCardRowItemCls: @payment-step-card-row-item-cls;
  paymentCardFormCls: @payment-card-form-cls;
  paymentCardInputCls: @payment-card-input-cls;
  paymentCardInputNumberCls: @payment-card-input-number-cls;
  paymentCardInputBrandCls: @payment-card-input-brand-cls;
  paymentCardInputValidThruCls: @payment-card-input-validthru-cls;
  paymentCardInputCvcCls: @payment-card-input-cvc-cls;
  paymentCardInputCVCContainerCls: @payment-card-input-cvc-container-cls;
  paymentCardFormRowCls: @payment-card-form-row-cls;
  paymentCardItemStripeCls: @payment-card-item-stripe-cls;
  paymentCardInputStripeCls: @payment-card-input-stripe-cls;
  inputHeight: @ra-form-input-height;
  inputBorder: @ra-form-input-border solid @ra-form-input-border-color;
  inputBorderFocus: @ra-form-input-border solid @ra-form-input-border-color-hover;
  inputVerticalMargin: @ra-form-inline-item-vmargin + 5;
  inputPadding: 4px 11px;
  paymentBoxBorder: 1px solid @ra-color-grayscale-1;
  paymentOptionTabSelectedBkg: @ra-color-grayscale-50;
  colorBackground: @ra-color-background;
  colorText: @ra-color-text;
  colorTextSecondary: @ra-color-grayscale-40;
  colorSuccess: @ra-form-input-border-color-hover;
  colorError: @ra-form-input-warning-color;
  fontSize: @ra-font-size;
  fontFamily: @ra-font-base;
  formItemCls: @ra-form-item-cls;
  formItemExplainErrorCls: @ra-form-item-explain-error-cls;
}

.@{payment-step-card-row-cls} {
  margin: 15px 0;

  .ant-radio {
    margin-top: -5px;
  }

  .@{payment-step-card-row-item-cls} {
    display: flex;
    align-items: center;
    line-height: 1.4;

    .ra-payment-card {
      margin: 0 30px 0 10px;
    }
  }
}

.@{payment-card-form-cls}.ra-form {
  .ra-form-item.ant-form-item .ant-row {
    .ant-form-item-label {
      min-width: 190px;
    }
  }

  .@{payment-card-input-cls}.ra-form-item {
    &.@{payment-card-input-number-cls} {
      position: relative;

      .ant-input {
        padding-left: 65px;
      }

      .@{payment-card-input-brand-cls} {
        position: absolute;
        top: 0;
        left: 12px;
        max-height: 40px;
        z-index: @ra-zindex-1;
      }
    }

    &.@{payment-card-input-validthru-cls}.ant-form-item {
      flex-grow: 1;

      > .ant-col {
        width: 50%;
      }
    }

    &.@{payment-card-input-cvc-cls}.ra-form-item {
      position: relative;
      width: 180px;
      margin-left: 15px;

      @media only screen and (min-width: @ra-screen-sm) {
        .ant-form-item-control {
          width: 50%;
        }

        .ant-form-item-label {
          min-width: 50px;
        }
      }

      .@{payment-card-input-cvc-container-cls} {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: @ra-zindex-1;
      }
    }
  }

  .@{payment-card-item-stripe-cls}.ra-form-item .ant-row {
    .ant-form-item-control {
      line-height: 0.8;
    }
  }

  .@{payment-card-input-stripe-cls} {
    padding-top: 11px;
  }
}

.@{payment-card-form-row-cls} {
  display: flex;
  justify-content: space-between;
}
