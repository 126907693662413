@import (reference) '~@riseart/antd-provider/dist/website/variables.less';
@import (reference) '~@riseart/antd-provider/dist/website/mixins.less';

@black-friday-cls: ~'@{ra-prefix}-black-friday';
@black-friday-with-counter-cls: ~'@{black-friday-cls}-with-counter';
@black-friday-light-cls: ~'@{black-friday-cls}-light';
@black-friday-condensed-cls: ~'@{black-friday-cls}-condensed';
@black-friday-title-cls: ~'@{black-friday-cls}-title';
@black-friday-title-label-cls: ~'@{black-friday-title-cls}-label';
@black-friday-subtitle-label-cls: ~'@{black-friday-cls}-subtitle-label';
@black-friday-content-cls: ~'@{black-friday-cls}-content';
@black-friday-content-cell-cls: ~'@{black-friday-content-cls}-cell';
@black-friday-content-cell-items-cls: ~'@{black-friday-content-cell-cls}-items';
@black-friday-countdown-cls: ~'@{black-friday-cls}-countdown';
@black-friday-countdown-label-cls: ~'@{black-friday-countdown-cls}-label';
@black-friday-countdown-close-cls: ~'@{black-friday-countdown-cls}-close';

:export {
  blackFridayCls: @black-friday-cls;
  blackFridayWithCounterCls: @black-friday-with-counter-cls;
  blackFridayLightCls: @black-friday-light-cls;
  blackFridayCondensedCls: @black-friday-condensed-cls;
  blackFridayTitleCls: @black-friday-title-cls;
  blackFridayTitleLabelCls: @black-friday-title-label-cls;
  blackFridaySubTitleLabelCls: @black-friday-subtitle-label-cls;
  blackFridayContentCls: @black-friday-content-cls;
  blackFridayContentCellCls: @black-friday-content-cell-cls;
  blackFridayContentCellItemsCls: @black-friday-content-cell-items-cls;
  blackFridayCountdownCls: @black-friday-countdown-cls;
  blackFridayCountdownLabelCls: @black-friday-countdown-label-cls;
  blackFridayCountdownCloseCls: @black-friday-countdown-close-cls;
}

.@{black-friday-cls} {
  position: relative;
  margin: -15px;
  padding: 15px;

  @media only screen and (min-width: @ra-screen-md) {
    padding: 5px;
  }

  &.@{black-friday-with-counter-cls} {
    .@{black-friday-title-cls} {
      text-transform: none;
      padding-bottom: 0;

      @media only screen and (min-width: @ra-screen-md) {
        padding-bottom: 0;
      }

      .@{black-friday-title-label-cls} {
        line-height: 1;
        margin-bottom: 0;

        @media only screen and (max-width: @ra-screen-md-max) {
          padding-left: 20px;
          padding-right: 20px;
          margin-bottom: 0;
        }

        @media only screen and (min-width: @ra-screen-md) {
          margin-top: 8px;
        }

        @media only screen and (min-width: @ra-screen-lg) {
          margin-bottom: 0;
          padding-top: 0;
        }
      }
    }

    .@{black-friday-subtitle-label-cls} {
      margin-bottom: 10px;
    }

    .@{black-friday-content-cls} {
      @media only screen and (min-width: @ra-screen-lg) {
        font-size: 14px;
      }
    }
  }

  &.@{black-friday-light-cls} {
    padding: 15px;

    .@{black-friday-title-cls} {
      text-transform: none;
      padding-bottom: 0;

      @media only screen and (min-width: @ra-screen-md) {
        padding-bottom: 7px;
      }

      .@{black-friday-title-label-cls} {
        line-height: 1;
        margin-bottom: 0;

        @media only screen and (max-width: @ra-screen-md-max) {
          padding-left: 20px;
          padding-right: 20px;
          margin-bottom: 0;
        }

        @media only screen and (min-width: @ra-screen-lg) {
          margin-bottom: 0;
          padding-top: 0;
        }
      }
    }

    .@{black-friday-content-cls} {
      @media only screen and (min-width: @ra-screen-lg) {
        font-size: 14px;
      }
    }
  }

  &.@{black-friday-condensed-cls} {
    .ra-btn.ra-btn-link {
      width: 100%;
      font-size: 18px;
      font-weight: @ra-font-weight-normal;
      text-align: center;
      padding-left: 20px;
      padding-right: 20px;
      .mixinTextOverflow();

      &:hover,
      &:active,
      &:focus {
        font-weight: @ra-font-weight-normal;
      }
    }

    .@{black-friday-countdown-close-cls} {
      position: absolute;
      top: -7px;
      right: -5px;
      font-weight: @ra-font-weight-normal;
    }

    @media only screen and (min-width: @ra-screen-md) {
      padding: 10px;
    }
  }

  .@{black-friday-title-cls} {
    padding-bottom: 10px;
    font-size: 18px;
    font-weight: @ra-font-weight-normal;

    @media only screen and (min-width: @ra-screen-md) {
      display: flex;
      justify-content: center;
    }

    @media only screen and (min-width: @ra-screen-lg) {
      padding-bottom: 15px;
    }

    .@{black-friday-title-label-cls} {
      line-height: 1;
      margin-bottom: 15px;

      @media only screen and (max-width: @ra-screen-sm-max) {
        padding-left: 20px;
        padding-right: 20px;
        margin-bottom: 10px;
        line-height: 1.05;
      }

      @media only screen and (min-width: @ra-screen-md) {
        margin-bottom: 0;
        padding-top: 0;
        line-height: 38px;
      }
    }
  }

  .@{black-friday-subtitle-label-cls} {
    @media only screen and (max-width: @ra-screen-md-max) {
      font-size: 14px;
    }
  }

  .@{black-friday-content-cls} {
    font-size: 14px;

    @media only screen and (min-width: @ra-screen-md) {
      display: flex;
      flex-flow: row-reverse wrap;
      justify-content: center;
    }

    @media only screen and (min-width: @ra-screen-lg) {
      font-size: 12px;
    }

    .@{black-friday-content-cell-cls} {
      .@{black-friday-content-cell-items-cls} {
        @media only screen and (min-width: @ra-screen-lg) {
          text-align: left;
        }

        > div {
          > div {
            display: inline;
          }

          @media only screen and (min-width: @ra-screen-lg) {
            display: inline-block;
            padding: 0 9px;
            border-right: 1px solid white;

            &:last-child {
              border-right: none;
            }

            > div {
              display: block;
            }
          }

          @media only screen and (min-width: @ra-screen-xl) {
            padding: 0 10px;
          }
        }
      }

      .ra-countdown {
        @media only screen and (min-width: @ra-screen-md) {
          margin-top: -10px;
        }
      }
    }
  }

  .@{black-friday-countdown-cls} {
    display: flex;
    justify-content: center;
    margin: 5px auto;
    min-width: 280px;

    @media only screen and (min-width: @ra-screen-md) {
      margin: 0;
      padding-right: 10px;
    }

    .@{black-friday-countdown-label-cls} {
      margin-right: 10px;
      line-height: 40px;
      font-size: 14px;
      font-weight: @ra-font-weight-light;

      @media only screen and (min-width: @ra-screen-md) {
        padding-top: 15px;
        line-height: 33px;
        font-size: 18px;
        font-weight: @ra-font-weight-normal;
      }
    }
  }

  .@{black-friday-countdown-close-cls} {
    position: absolute;
    top: 5px;
    right: 5px;
    padding: 5px;
    font-size: 26px;
    line-height: 1;
    cursor: pointer;
  }
}
