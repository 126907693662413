@import (reference) '~@riseart/antd-provider/dist/website/variables.less';

@artist-art-slider-cls: ~'@{ra-prefix}-artist-art-slider';

:export {
  artistArtSliderCls: @artist-art-slider-cls;
}

.@{artist-art-slider-cls}.ra-slider.ra-slider-horizontal {
  .slick-arrow {
    top: 50%;
  }
}
