@import (reference) '~@riseart/antd-provider/dist/website/variables.less';

@file-dropzone-cls: ~'@{ra-prefix}-file-dropzone';

:export {
  fileDropzoneCls: @file-dropzone-cls;
}

.@{file-dropzone-cls} {
  cursor: pointer;
}
