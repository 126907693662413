@import (reference) '~@riseart/antd-provider/dist/website/variables.less';
@import (reference) '~@riseart/antd-provider/dist/website/mixins.less';

@xmas-cls: ~'@{ra-prefix}-xmas';
@xmas-icon-cls: ~'@{xmas-cls}-icon';
@xmas-content-cls: ~'@{xmas-cls}-content';
@xmas-content-main-cls: ~'@{xmas-content-cls}-main';

:export {
  xmaxCls: @xmas-cls;
  xmaxIconCls: @xmas-icon-cls;
  xmaxContentCls: @xmas-content-cls;
  xmaxContentMainCls: @xmas-content-main-cls;
}

.@{xmas-cls} {
  display: flex;
  justify-content: center;

  .@{xmas-icon-cls} {
    margin-right: 15px;
    font-size: 36px;
    line-height: 1;

    .anticon {
      vertical-align: middle;
    }
  }

  .@{xmas-content-cls} {
    text-align: left;

    @media only screen and (min-width: @ra-screen-lg) {
      line-height: 45px;
    }

    .@{xmas-content-main-cls} {
      display: block;

      @media only screen and (min-width: @ra-screen-lg) {
        display: inline-block;
      }
    }

    strong,
    .ra-link {
      font-weight: @ra-font-weight-normal;
    }
  }
}
