@import (reference) '~@riseart/antd-provider/dist/website/variables.less';

@country-cls: ~'@{ra-prefix}-country-flag';

:export {
  countryCls: @country-cls;
}

.@{country-cls} {
  img {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 1px solid @ra-color-white;
  }
}
