@import (reference) '~@riseart/antd-provider/dist/website/variables.less';

@confirmation-cls: ~'@{ra-prefix}-confirmation-overlay';
@confirmation-footer-cls: ~'@{confirmation-cls}-footer';

:export {
  confirmationCls: @confirmation-cls;
  confirmationFooterCls: @confirmation-footer-cls;
}

.@{confirmation-cls} {
  .@{confirmation-footer-cls} {
    display: flex;
    justify-content: space-between;
    padding: 25px;
  }
}
