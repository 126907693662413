@import (reference) '~@riseart/antd-provider/dist/website/variables.less';
@import (reference) '~@riseart/antd-provider/dist/website/mixins.less';
@import (reference) '~@riseart/layout/es/menu/horizontal/Menu.less';
@import (reference) '~@riseart/layout/es/header/Header.less';
@import (reference) '../../search/Results.less';

@header-search-opacity: 0.75;
@header-height-sm: 50px;
@header-height-lg: 92px;
@header-cls: ~'@{ra-prefix}-header';
@header-container-cls: ~'@{header-cls}-container';
@header-row-cls: ~'@{header-cls}-row';
@header-row-first-cls: ~'@{header-row-cls}-first';
@header-row-search-focused-cls: ~'@{header-row-cls}-search-focused';
@header-transparent-cls: ~'@{header-cls}-transparent';
@header-active-cls: ~'@{header-cls}-active';
@header-column-left-cls: ~'@{header-cls}-col-left';
@header-column-center-cls: ~'@{header-cls}-col-center';
@header-column-right-cls: ~'@{header-cls}-col-right';
@header-country-cls: ~'@{header-cls}-country';
@header-search-back-btn-cls: ~'@{header-cls}-search-back-btn';
@header-sticky-drawer-cls: ~'@{header-cls}-sticky-drawer';

:export {
  headerCls: @header-cls;
  headerTransparentCls: @header-transparent-cls;
  headerActiveCls: @header-active-cls;
  headerContainerCls: @header-container-cls;
  headerRowCls: @header-row-cls;
  headerRowFirstCls: @header-row-first-cls;
  headerRowSearchFocusedCls: @header-row-search-focused-cls;
  headerColumnLeftCls: @header-column-left-cls;
  headerColumnCenterCls: @header-column-center-cls;
  headerColumnRightCls: @header-column-right-cls;
  headerCountryCls: @header-country-cls;
  headerSearchBackBtnCls: @header-search-back-btn-cls;
  headerStickyDrawerCls: @header-sticky-drawer-cls;
}

.@{header-cls} {
  position: relative;
  width: 100%;
  min-width: @ra-screen-xs;
  height: @header-height-sm;
  border-bottom: 1px solid @ra-menu-border-color;
  background-color: @ra-color-white;
  z-index: @ra-zindex-header;

  @media only screen and (min-width: @ra-screen-lg) {
    height: @header-height-lg;
  }

  .@{header-search-back-btn-cls}.ra-btn.ant-btn {
    display: none;
    margin-right: 10px;
    padding: 0 10px;
    border: none;
    background: transparent;
    font-size: 18px;
    height: 36px;
    line-height: 36px;
    color: @ra-color-text;
  }

  // Header Active States
  &.@{header-active-cls} {
    @media only screen and (min-width: @ra-screen-lg) {
      background-color: @ra-color-white;
      border-bottom: 1px solid @ra-menu-border-color;
    }
  }

  // Header Transparent
  &.@{header-transparent-cls} {
    background-color: transparent;
    border-bottom: none;

    &.@{header-active-cls} {
      border-bottom: 1px solid @ra-color-grayscale-30;
      background-color: @ra-color-white;

      @media only screen and (min-width: @ra-screen-lg) {
        background-color: rgba(255, 255, 255, @header-opacity);
      }
    }

    .@{menu-submenu-wrapper-cls} {
      @media only screen and (min-width: @ra-screen-lg) {
        background-color: rgba(@ra-color-white, @header-opacity);
      }
    }

    .@{header-column-center-cls} {
      .ra-menu-horizontal-submenu-background {
        @media only screen and (min-width: @ra-screen-lg) {
          background-color: rgba(@ra-color-white, @header-opacity);
        }
      }
    }

    &:not(.@{header-active-cls}) {
      .@{header-search-back-btn-cls} {
        color: @ra-color-white;
      }

      .ra-search-form.ra-form.ant-form {
        .ra-search-form-row .ra-form-item.ra-search-field-wrapper {
          .ra-search-field {
            background-color: rgba(@ra-color-white, @header-search-opacity);
          }
        }

        .ra-form-item.ra-search-field-wrapper,
        .ra-form-item.ra-search-btn-wrapper {
          .ra-form-input {
            border-bottom: 1px solid transparent;

            &,
            .ant-input {
              height: 36px;
              min-height: 36px;
            }

            .ant-input {
              .mixinPlaceholderStyles({ color: @ra-color-grayscale-40 });
            }
          }
        }
      }
    }
  }

  .@{header-container-cls} {
    margin: 0 auto;
    max-width: @ra-layout-width-max;

    .@{header-row-cls} {
      display: flex;
      justify-content: space-between;
      height: 100%;

      &.@{header-row-first-cls} {
        .@{header-column-left-cls} {
          @media only screen and (min-width: @ra-screen-lg) {
            padding-top: 5px;
            padding-bottom: 0;
          }
        }

        .@{header-column-center-cls} {
          padding-top: 8px;
          padding-bottom: 0;
        }

        .@{header-column-right-cls} {
          padding-top: 4px;
        }
      }

      &:not(.@{header-row-first-cls}) {
        @media only screen and (min-width: @ra-screen-lg) {
          .@{header-column-left-cls} {
            &:before {
              content: '';
              display: block;
              width: 130px;
            }
          }
        }
      }
    }

    &.@{header-row-search-focused-cls} {
      .@{header-column-left-cls},
      .@{header-column-right-cls} {
        display: none;
      }

      .@{header-search-back-btn-cls}.ra-btn.ant-btn {
        display: block;
      }
    }
  }

  // Header Columns
  .@{header-column-left-cls} {
    padding: 3px 5px;

    @media only screen and (min-width: @ra-screen-lg) {
      padding-right: 10px;
      padding-left: 15px;
    }

    .ra-logo {
      height: auto;
    }
  }

  .@{header-column-center-cls} {
    flex-grow: 1;
    padding-left: 15px;
    padding-right: 15px;

    @media only screen and (max-width: @ra-screen-md-max) {
      padding: 6px 10px 0;
      z-index: @ra-zindex-0;
    }

    .ra-search-form.ra-form.ant-form {
      padding: 0 0 6px;

      @media only screen and (min-width: @ra-screen-lg) {
        padding-bottom: 0;
      }

      .ra-form-item.ra-search-field-wrapper,
      .ra-form-item.ra-search-btn-wrapper {
        margin: 0;

        .ra-search-field {
          background-color: @ra-color-background;
          border-bottom: 1px solid @ra-color-grayscale-30;

          &.ra-form-input.ant-input-affix-wrapper-focused {
            border-bottom-color: @ra-color-primary-20;
          }

          .ant-input-prefix {
            font-size: 18px;
            margin-top: -2px;
          }
        }

        .ra-form-input {
          &,
          .ant-input {
            min-height: 36px;
            height: 36px;
            font-size: 14px;
          }
        }

        .ra-submit-btn {
          margin: 0;
          padding: 1px 10px;
          height: 36px;
          min-height: 36px;
          font-size: 22px;
          line-height: 36px;
        }
      }
    }

    .ra-menu-list.ra-menu-list-border .ra-menu-list-item {
      padding-left: 0;
      border: none;
    }
  }

  .@{header-column-right-cls} {
    position: static;
    height: 100%;
    padding-top: 5px;
    padding-right: 10px;
    white-space: nowrap;

    @media only screen and (min-width: @ra-screen-lg) {
      padding-top: 0;
    }

    .ra-menu-horizontal,
    .ra-hamburger {
      display: inline-block;
    }

    .ra-hamburger {
      position: relative;
      top: 5px;
      margin-left: 10px;
    }
  }

  .@{header-column-left-cls},
  .@{header-column-right-cls} {
    @media only screen and (max-width: @ra-screen-md-max) {
      z-index: @ra-zindex-1;
    }
  }

  .ra-menu-horizontal {
    height: auto;

    .ra-menu-horizontal-item .ra-menu-horizontal-submenu-menu > ul {
      padding-top: 30px;
    }
  }

  .ra-menu-horizontal-submenu-wrapper .ra-wrapper {
    @media only screen and (max-width: @ra-screen-sm-max) {
      padding-left: 0;
      padding-right: 0;

      .ra-menu-horizontal-submenu-close {
        right: 0;
      }

      .ra-search-visual-hint {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  .@{header-country-cls} {
    font-weight: @ra-font-weight-light;
  }

  .@{search-results-cls} {
    height: calc(100vh - @header-height-sm);
    background-color: @ra-color-white;
    border-top: 1px solid @ra-color-border;
    // stylelint-disable declaration-block-no-redundant-longhand-properties
    overflow-y: auto;
    overflow-x: hidden;
    // stylelint-enable

    @media only screen and (min-width: @ra-screen-lg) {
      height: calc(100vh - @header-height-lg);
    }
  }
}

.@{header-sticky-drawer-cls} {
  position: relative;
  z-index: @ra-zindex-header;
}
