@import (reference) '~@riseart/antd-provider/dist/website/variables.less';

@cvc-icon-cls: ~'@{ra-prefix}-cvc-icon';

:export {
  cvcIconCls: @cvc-icon-cls;
}

.@{cvc-icon-cls} {
  position: relative;
  display: inline-block;
  width: 24px;
  height: 16px;
  border: 1px solid  @ra-color-grayscale-30;
  border-radius: 2px;

  &:before {
    content: ' ';
    position: absolute;
    top: 3px;
    left: 0;
    width: 100%;
    height: 1px;
    background: @ra-color-grayscale-30;
  }
  
  &:after {
    content: ' ';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 15px;
    height: 10px;
    border: 1px solid @ra-color-secondary3-20;
  }
}

.ant-input-affix-wrapper {
  .ant-input-suffix {
    .@{cvc-icon-cls},
    .ra-hint {
      line-height: 0;
    }
  }
}
