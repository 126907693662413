@import (reference) '~@riseart/antd-provider/dist/website/variables.less';

@consent-msg-cls: ~'@{ra-prefix}-consent-msg';
@consent-msg-text-cls: ~'@{consent-msg-cls}-text';
@consent-msg-buttons-cls: ~'@{consent-msg-cls}-buttons';
@consent-msg-buttons-single-cls: ~'@{consent-msg-buttons-cls}-single';
@consent-msg-main-btn-cls: ~'@{consent-msg-cls}-main-btn';

:export {
  consentMsgCls: @consent-msg-cls;
  consentMsgTextCls: @consent-msg-text-cls;
  consentMsgButtonsCls: @consent-msg-buttons-cls;
  consentMsgButtonsSingleCls: @consent-msg-buttons-single-cls;
  consentMsgMainBtnCls: @consent-msg-main-btn-cls;
}

.@{consent-msg-cls} {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  @media only screen and (min-width: @ra-screen-md) {
    flex-direction: row;
  }

  .@{consent-msg-text-cls} {
    text-align: left;
  }

  .@{consent-msg-buttons-cls} {
    margin-top: 20px;
    margin-left: 0;
    white-space: nowrap;
    flex-grow: 1;
    width: 100%;

    @media only screen and (min-width: @ra-screen-md) {
      margin-top: 0;
      margin-left: 20px;
      width: auto;
    }

    &:not(.@{consent-msg-buttons-single-cls}) {
      @media only screen and (max-width: @ra-screen-sm-max) {
        .ra-btn.ant-btn {
          width: calc(50% - 7px);
        }
      }

      .ra-btn:last-child {
        margin-left: 14px;
      }
    }

    &.@{consent-msg-buttons-single-cls} {
      text-align: right;

      @media only screen and (max-width: @ra-screen-sm-max) {
        .ra-btn.ant-btn {
          width: 100%;
        }
      }
    }
  }

  .ra-btn.ra-btn-ghost.ant-btn {
    &.@{consent-msg-main-btn-cls} {
      background-color: @ra-color-white;
      color: @ra-color-text;

      &:hover {
        background-color: @ra-color-primary;
        border-color: @ra-color-primary;
        color: @ra-color-white;
      }
    }
  }
}
