@import (reference) '~@riseart/antd-provider/dist/website/variables.less';

@file-upload-info-cls: ~'@{ra-prefix}-file-upload-info';
@file-upload-info-item-cls: ~'@{file-upload-info-cls}-item';
@file-upload-info-icon-cls: ~'@{file-upload-info-cls}-icon';
@file-upload-info-content-cls: ~'@{file-upload-info-cls}-content';
@file-upload-info-status-cls: ~'@{file-upload-info-cls}-status';
@file-upload-info-progress-cls: ~'@{file-upload-info-cls}-progress';
@file-upload-info-message-cls: ~'@{file-upload-info-cls}-message';
@file-upload-info-error-cls: ~'@{file-upload-info-cls}-error';

:export {
  fileUploadInfoCls: @file-upload-info-cls;
  fileUploadInfoItemCls: @file-upload-info-item-cls;
  fileUploadInfoIconCls: @file-upload-info-icon-cls;
  fileUploadInfoContentCls: @file-upload-info-content-cls;
  fileUploadInfoStatusCls: @file-upload-info-status-cls;
  fileUploadInfoProgressCls: @file-upload-info-progress-cls;
  fileUploadInfoMessageCls: @file-upload-info-message-cls;
  fileUploadInfoErrorCls: @file-upload-info-error-cls;
}

.@{file-upload-info-cls} {
  .@{file-upload-info-error-cls} {
    color: @ra-color-secondary3-40;
  }
  .@{file-upload-info-item-cls} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;

    &:first-child {
      margin-top: 0;
    }

    .@{file-upload-info-icon-cls},
    .@{file-upload-info-status-cls} {
      font-size: 22px;
      text-align: center;
    }

    .@{file-upload-info-content-cls} {
      flex-grow: 1;
      margin: 0 10px;
    }

    .@{file-upload-info-progress-cls} {
      height: 4px;
      margin-top: 5px;
      background-color: @ra-color-grayscale-20;

      > div {
        height: 4px;
        background-color: @ra-color-primary;
      }
    }

    .@{file-upload-info-message-cls} {
      font-size: 13px;
    }
  }
}
