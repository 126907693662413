@import (reference) '~@riseart/antd-provider/dist/website/variables.less';

@app-messages-bottom-cls: ~'@{ra-prefix}-app-msg-bottom';

:export {
  appMessagesBottomCls: @app-messages-bottom-cls;
}

.@{app-messages-bottom-cls} {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 200;
  max-width: 1440px;
  padding: 0 10px 10px;
  left: 50%;
  transform: translateX(-50%);

  @media only screen and (min-width: @ra-screen-md) {
    padding: 0 50px 15px;
  }

  .ra-message {
    padding: 10px 15px;
    box-shadow: 0 0 5px @ra-color-black;
  }
}
