@import (reference) '~@riseart/antd-provider/dist/website/variables.less';

@grid-noresults-cls: ~'@{ra-prefix}-grid-noresults';

:export {
  gridNoresultsCls: @grid-noresults-cls;
}

.@{grid-noresults-cls} {
  position: relative !important;
  padding: 60px 20px !important;
  text-align: center !important;
}
