@import (reference) '~@riseart/antd-provider/dist/website/variables.less';

@page-head-wrapper-cls: ~'@{ra-prefix}-page-head-wrapper';

:export {
  pageHeadWrapperCls: @page-head-wrapper-cls;
}

.@{page-head-wrapper-cls} {
  margin-top: 25px;
  margin-bottom: 25px;

  @media only screen and (min-width: @ra-screen-md) {
    margin-top: 35px;
    margin-bottom: 35px;
  }
}
