@import (reference) '~@riseart/antd-provider/dist/website/variables.less';

@cart-empty-cls: ~'@{ra-prefix}-cart-empty';
@cart-empty-sidebar-cls: ~'@{cart-empty-cls}-sidebar';

:export {
  cartEmptyCls: @cart-empty-cls;
  cartEmptySidebarCls: @cart-empty-sidebar-cls;
}

.@{cart-empty-cls} {
  .ra-heading-level-5 {
    margin-bottom: 8px;
  }
}

.@{cart-empty-sidebar-cls} {
  margin: 40px 0;
  text-align: center;
  font-weight: @ra-font-weight-normal;
}
