@import (reference) '~@riseart/antd-provider/dist/website/variables.less';

@layout-content-cls: ~'@{ra-prefix}-layout-content';

:export {
  layoutContentCls: @layout-content-cls;
}

.@{layout-content-cls} {
  width: 100%;
  padding: 0;
  background-color: @ra-color-white;
}
