@import (reference) '~@riseart/antd-provider/dist/website/variables.less';

@icon-close-cls: ~'@{ra-prefix}-icon-close';

:export {
  iconCloseBtn: @icon-close-cls;
}

.@{icon-close-cls} {
  font-size: 20px;
  cursor: pointer;
}
