@import (reference) '~@riseart/antd-provider/dist/website/variables.less';
@import (reference) '~@riseart/antd-provider/dist/website/mixins.less';

@ownart-cls: ~'@{ra-prefix}-ownart';

:export {
  ownArtCls: @ownart-cls;
}

.@{ownart-cls} {
  display: inline-block;

  span {
    .mixinTextLevel4Style();
    
    float: left;
    font-weight: @ra-font-weight-normal;
    line-height: 40px;
    margin-right: 10px;
  }

  &:after {
    content: ' ';
    display: inline-block;
    width: 40px;
    height: 40px;
    background: transparent url('./images/ownart.svg') top center no-repeat;
  }
}
