@import (reference) '~@riseart/antd-provider/dist/website/variables.less';

@cart-credit-section-cls: ~'@{ra-prefix}-cart-credit-section';

:export {
  cartCreditSectionCls: @cart-credit-section-cls;
}

.@{cart-credit-section-cls} {
  .ra-heading {
    display: flex;
    justify-content: space-between;
  }
}
