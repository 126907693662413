@import (reference) '~@riseart/antd-provider/dist/website/variables.less';

@checkout-details-cls: ~'@{ra-prefix}-checkout-details';
@checkout-details-row-cls: ~'@{checkout-details-cls}-row';
@checkout-details-card-cls: ~'@{checkout-details-cls}-card';

:export {
  checkoutDetailsCls: @checkout-details-cls;
  checkoutDetailsRowCls: @checkout-details-row-cls;
  checkoutDetailsCardCls: @checkout-details-card-cls;
}

.@{checkout-details-cls} {
  .@{checkout-details-row-cls} {
    margin: 10px 0;

    &:first-child {
      margin-top: 0;
    }
  }
}

.@{checkout-details-card-cls} {
  margin-top: 25px;

  &:first-child {
    margin-top: 0;
  }

  .ra-heading {
    font-weight: @ra-font-weight-bold;
    text-transform: uppercase;
  }
}
