@import (reference) '~@riseart/antd-provider/dist/website/variables.less';
@import (reference) '~@riseart/antd-provider/dist/website/mixins.less';

@countdown-cls: ~'@{ra-prefix}-countdown';
@countdown-variant-default-cls: ~'@{countdown-cls}-default';
@countdown-variant-outlined-cls: ~'@{countdown-cls}-outlined';
@countdown-section-cls: ~'@{countdown-cls}-section';
@countdown-label-cls: ~'@{countdown-cls}-label';
@countdown-digit-cls: ~'@{countdown-cls}-digit';
@countdown-separtor-cls: ~'@{countdown-cls}-separator';

:export {
  countdownCls: @countdown-cls;
  countdownVariantDefaultCls: @countdown-variant-default-cls;
  countdownVariantOutlinedCls: @countdown-variant-outlined-cls;
  countdownSectionCls: @countdown-section-cls;
  countdownLabelCls: @countdown-label-cls;
  countdownDigitCls: @countdown-digit-cls;
  countdownSeparatorCls: @countdown-separtor-cls;
}

.@{countdown-cls} {
  display: inline-block;
  .mixinClearFix();

  .@{countdown-section-cls} {
    float: left;

    .@{countdown-label-cls},
    .@{countdown-digit-cls} {
      display: block;
      text-align: center;
      overflow: hidden;
    }

    .@{countdown-label-cls} {
      height: 15px;
      font-size: 10px;
      background: @ra-color-white;
      color: @ra-color-black;
      text-transform: uppercase;
    }

    .@{countdown-digit-cls} {
      width: 36px;
      height: 25px;
      line-height: 25px;
      background: @ra-color-white;
      font-size: 24px;
      color: @ra-color-black;

      @media only screen and (min-width: @ra-screen-md) {
        width: 40px;
      }
    }
  }

  // Default variant
  &.@{countdown-variant-default-cls} {
    .@{countdown-section-cls} {
      .@{countdown-label-cls} {
        @media only screen and (min-width: @ra-screen-md) {
          color: @ra-color-white;
          background: transparent;
        }
      }

      .@{countdown-digit-cls} {
        line-height: 25px;

        @media only screen and (min-width: @ra-screen-md) {
          height: 33px;
          line-height: 35px;
        }
      }
    }

    .@{countdown-separtor-cls} {
      margin-top: 8px;
      font-size: 32px;
      line-height: 32px;

      @media only screen and (min-width: @ra-screen-md) {
        margin-top: 13px;
      }
    }
  }

  // Outlined variant
  &.@{countdown-variant-outlined-cls} {
    .@{countdown-section-cls} {
      .@{countdown-digit-cls} {
        line-height: 30px;

        @media only screen and (min-width: @ra-screen-md) {
          height: 23px;
        }
      }
    }

    .@{countdown-separtor-cls} {
      margin-top: 1px;
      font-size: 25px;
      line-height: 23px;
    }
  }

  .@{countdown-separtor-cls} {
    float: left;
    padding: 0 5px;
  }
}
