@import (reference) '~@riseart/antd-provider/dist/website/variables.less';

@search-results-cls: ~'@{ra-prefix}-search-results';
@search-results-section-top-cls: ~'@{search-results-cls}-section-top';
@search-results-section-top-summary-cls: ~'@{search-results-section-top-cls}-summary';
@search-results-section-artists-cls: ~'@{search-results-cls}-section-artists';
@search-results-section-action-cls: ~'@{search-results-cls}-section-action';
@search-results-section-artworks-cls: ~'@{search-results-cls}-section-artworks';

:export {
  searchResultsCls: @search-results-cls;
  searchResultsSectionTopCls: @search-results-section-top-cls;
  searchResultsSectionTopSummaryCls: @search-results-section-top-summary-cls;
  searchResultsSectionArtistsCls: @search-results-section-artists-cls;
  searchResultsSectionActionCls: @search-results-section-action-cls;
  searchResultsSectionArtworksCls: @search-results-section-artworks-cls;
}

.@{search-results-cls} {
  @media only screen and (max-width: @ra-screen-xs-max) {
    padding-bottom: 50px;
  }

  .@{search-results-section-action-cls} {
    display: flex;
    align-items: center;

    .ra-link.ra-btn {
      margin-left: 15px;
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .@{search-results-section-top-cls}.ra-section {
    margin: 10px 0;
    padding: 0;

    @media only screen and (min-width: @ra-screen-md) {
      margin: 0 0 20px;
      padding-top: 20px;
    }

    .ra-wrapper > div {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .@{search-results-section-top-summary-cls} {
        @media only screen and (min-width: @ra-screen-md) {
          font-size: 18px;
        }

        > span {
          color: @ra-color-primary;
        }
      }

      .ra-btn {
        padding-left: 15px;
        padding-right: 15px;
        margin-right: 10px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .@{search-results-section-artists-cls} {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .@{search-results-section-artworks-cls} {
    margin-top: 0;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .@{search-results-section-artists-cls},
  .@{search-results-section-artworks-cls} {
    .ra-grid-noresults {
      padding-top: 30px !important;
      padding-bottom: 30px !important;
    }

    .ra-section-content .ra-section-content-title.ra-section-content-title-flex {
      flex-direction: row;
      align-items: center;
    }
  }
}
