@import (reference) '~@riseart/antd-provider/dist/website/variables.less';

@breadcrumbs-cls: ~'@{ra-prefix}-breadcrumbs';
@breadcrumbs-item-cls: ~'@{breadcrumbs-cls}-item';
@breadcrumbs-separator-cls: ~'@{breadcrumbs-cls}-separator';
@breadcrumbs-section-cls: ~'@{breadcrumbs-cls}-section';

:export {
  breadcrumbsCls: @breadcrumbs-cls;
  breadcrumbsItemCls: @breadcrumbs-item-cls;
  breadcrumbsSeparatorCls: @breadcrumbs-separator-cls;
  breadcrumbsSectionCls: @breadcrumbs-section-cls;
}

.@{breadcrumbs-cls} {
  padding: 10px 0;
  color: @ra-breadcrumbs-color;

  .@{breadcrumbs-separator-cls} {
    margin: 0 7px;
  }

  .@{breadcrumbs-item-cls} {
    font-size: 14px;
    color: @ra-breadcrumbs-color;
  }
}
