@import (reference) '~@riseart/antd-provider/dist/website/variables.less';
@import (reference) '~@riseart/antd-provider/dist/website/mixins.less';

@regional-label-cls: ~'@{ra-prefix}-regional-label';
@regional-label-placeholder-cls: ~'@{regional-label-cls}-placeholder';

:export {
  regionalLabelCls: @regional-label-cls;
  regionalLabelPlaceholderCls: @regional-label-placeholder-cls;
}

.@{regional-label-cls} {
  display: block !important;
  width: 100% !important;
  height: 100% !important;
  font-size: 14px !important;
  text-transform: uppercase;

  &:hover {
    color: @ra-color-primary !important;
    text-decoration: none !important;
  }

  img,
  .@{regional-label-placeholder-cls} {
    margin-right: 7px;
    margin-top: -3px;
  }

  .@{regional-label-placeholder-cls} {
    display: inline-block;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 1px solid @ra-color-white;
    background-color: @ra-color-background;
    vertical-align: middle;
  }
}
